import React, { useState } from "react"
import { Typography, Input, Button, Form, Spin } from "antd"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import ReCAPTCHA from "react-google-recaptcha"
import Layout from "../components/Layout"
import { emails } from "../data"
import { postFormData } from "../services/contact"
import "../styles/contact.scss"
import News from "../components/News"
import IconsAdded from "../components/IconsAdded"
const { Title } = Typography
const { TextArea } = Input

const url = "https://staging.visiomate.com/crmPipeline"

const Contact = ({ data, career }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [captchaValue, setCaptchaValue] = useState(null)

  const onCaptchaChange = value => {
    setCaptchaValue(value)
  }
  const onFinishForFirstForm = values => {
    handleFormData(values)
    form.resetFields()
  }
  const handleFormData = async values => {
    const { email, phone, message, firstName, lastName, subject } = values
    const formData = {
      name: "Contact Us: " + subject,
      contact_name: firstName + " "+ lastName,
      email_from: email,
      phone : phone,
      message ,
      description: message,
      email_to: emails.info,
      attachment_base64: "",
    }
    setLoading(true)
    await postFormData(formData, setLoading, url)
    setLoading(false)
  }
  const backgroundImage = data.contactbg.childImageSharp.fluid.src
  return (
    <Layout className="contact-us-container" data={data} 
    pageTitle={"Visiomate - Contact us"}
    descrip = {"Have questions or need assistance? Contact us today! Our friendly team is ready to help. Find our contact information, including phone, email, and address, here."}>
      <div
        className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="logo">
          <Link to="/">
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>
        <div className="headings-container">
          <Title>
            We're excited to connect with you! Whether you're seeking
            information about our cutting-edge services, exploring collaboration
            prospects, or just dropping by to say hello, our team is here and
            eager to support you.
          </Title>
        </div>
        {/* <button onClick={showModal}>Tell us about your project</button>
        <InfoModal
          companyIsEnabled
          url="https://staging.visiomate.com/crmPipeline"
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        /> */}
      </div>
      <div className="message">
        <p>
          Your thoughts and inquiries are important to us. We are here to assist
          you in any way possible, so please take a moment to share how we can
          be of service
        </p>
      </div>
      <div className="position-relative">
      <Form onFinish={onFinishForFirstForm}>
        <div className="form-container">
          <div>
            <div className="form-column">
              <Form.Item
                name="firstName"
                rules={[
                  { required: true, message: "Please enter your first name" },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[
                  { required: false, message: "Please enter your last name" },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: "Please enter your phone number" },
                  {
                    pattern: /^\+92\d{3}\d{7}$/,
                    message:
                      'Please enter a valid phone number in the format "+923000000000"',
                  },
                ]}
              >
                <Input placeholder="Phone #" />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item
                name="subject"
                rules={[
                  { required: true, message: "Please enter the subject" },
                ]}
              >
                <Input placeholder="Subject" />
              </Form.Item>
              <Form.Item
                name="message"
                rules={[
                  { required: false, message: "Please enter your message" },
                ]}
                
              >
                <TextArea  placeholder="Message" autoSize={{ minRows: 4, maxRows: 4 }}/>
              </Form.Item>
            </div>
          </div>
          <div className="form-column">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={captchaValue === null}
              >
                {loading ? <Spin /> : "Send"}
              </Button>
            </Form.Item>
          </div>
          <div className="recaptcha">
          <ReCAPTCHA
            sitekey="6Le4Xc4pAAAAACdoP1-v8vNKtHOYfkJiXrOBvi8V"
            onChange={onCaptchaChange}
            style={{ padding: "0% 0%" }}
          />
          </div>
        </div>
      </Form>
      <IconsAdded iconColor={"yellow"}/>
      </div>
      <div></div>
      <div className="main-div">
        <h1>Feel free to find us</h1>
        <div className="map-and-form-container">
          <div className="">
            <iframe
            title="Pakistan office location"
              className="image-map"
              style={{ width: "100%", border: "none" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.2537336474825!2d74.35323797621331!3d31.434680951369614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391903fc936aaaab%3A0x3a4403a7667c4e04!2sVisiomate!5e0!3m2!1sen!2s!4v1706611182963!5m2!1sen!2s"
            ></iframe>
          </div>
          <div className="VisiomateLoaction">
            <div>
              <span>
                <h1 style={{ fontWeight: 700 }} className="office-country">Pakistan Office</h1>
                <div className="flag">
                  <Img fluid={data.PKFlag.childImageSharp.fluid} />
                </div>
              </span>
              {/* <h1 className="company-name">VISIOMATE</h1> */}
              <p>
                1st Floor, 126 Lahore – Kasur Rd, Saroba Garden Housing
                Scoiety, Lahore, Punjab 54700, Pakistan,{" "}
              </p>
              <p><strong>PH#:</strong> +92 321 4050 200</p>
              <p><strong>Email:</strong> business@visiomate.com </p>
              <p>info@visiomate.com</p>
            </div>
            <div>
              <span>
                <h1 style={{ fontWeight: 700 }} className="office-country">USA Office</h1>
                <div className="flag">
                  <Img fluid={data.usFlag.childImageSharp.fluid} />
                </div>
              </span>
              {/* <h1 className="company-name">DIGITANK</h1> */}
              <p>711 Mulkerrin Ct Mars PA, USA 16046. </p>
              <p><strong>PH#:</strong> +1 412-297-9685</p>
              <p><strong>Email:</strong> info@digitank.com</p>
            </div>
          </div>
        </div>
      </div>

      <News isAbout={true} career={true} />
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query contactQuery {
    Dummy: file(relativePath: { eq: "dummy.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Map: file(relativePath: { eq: "contact/image 14.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PKFlag: file(
      relativePath: {
        eq: "contact/2634382_ensign_flag_nation_pakistan_icon.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    usFlag: file(relativePath: { eq: "contact/7849795_1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contactbg: file(relativePath: { eq: "contact_bg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
