import { message } from "antd"

export const postFormData = async (formData, loading, url) => {
  loading(true)

  try {
    const data = await fetch(url, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify(formData),
    })

    const response = await data.json()

    if (response.statusCode === 200 || response.result.status === true) message.success("Mail Sent Successfully.")
    else if (response.statusCode === 500 || response.result.status === false)
      message.error("Mail Not Sent! Try Again later!")
  } catch (error) {
    message.error("There is Some Error!")
  } finally {
    loading(false)
  }
}
